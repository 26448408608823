/* You can add global styles to this file, and also import other style files */

html,
body {
	background-color: #F4F2EE !important;
	height: 100%;
}

@font-face {
	font-family: "poppins";
	src: url(../Poppins/Poppins-Regular.ttf) format(truetype);
	src: url(../Poppins/Poppins-SemiBold.ttf) format(truetype);
	src: url(../Poppins/Poppins-Medium.ttf) format(truetype);
	src: url(../Poppins/Poppins-Light.ttf) format(truetype);
	src: url(../Poppins/Poppins-ExtraLight.ttf) format(truetype);
	src: url(../Poppins/Poppins-Thin.ttf) format(truetype);
}

body {
	font-family: "poppins" !important;
}

//--------
@keyframes pulse {
	0% {
		r: 8;
		opacity: 1;
	}

	50% {
		r: 18;
		opacity: 0.4;
	}

	100% {
		r: 8;
		opacity: 0.2;
	}
}

circle {
	animation: pulse 5s infinite;
}

//--------

.card-header {
	background-color: #dddddd;
	color: white;
	text-align: left;
	font-size: 20px;
	font-weight: 500;
}

.card-title {
	padding: 10px;
}

.loader {
	padding-top: 250px;
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: #f9f9f9;
	opacity: .8;
}

.ql-container {
	min-height: 300px;
}

button:disabled {
	cursor: not-allowed !important;
	pointer-events: all !important;
}

.chart-legend .legend-labels {
	white-space: normal !important;
	border-radius: 15px !important;
	background: rgb(255, 255, 255) !important;
	overflow-y: hidden !important;
}

.chart-legend .legend-label-color {
	height: 12px !important;
	// width: 10px !important;
	border-radius: 15px !important;
}

.chart-legend .legend-label-text {
	font-size: 11px !important;
	color: #8b8b8b !important;
}

.mat-mdc-card {
	background: #f5f5f5 !important;
}


.ag-format-container {
	//width: 400px;
	margin: 0 auto;
}

.ag-courses_box {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;

	//padding: 50px 0;
}

.ag-courses_item {
	//-ms-flex-preferred-size: calc(33.33333% - 30px);
	//flex-basis: calc(33.33333% - 30px);
	width: 100%;
	margin: 0 0px 10px;

	overflow: hidden;

	border-radius: 10px;
}

.ag-courses-item_link {
	display: block;
	padding: 16px;
	background-color: #555555;
	overflow: hidden;
	position: relative;
}

.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
	text-decoration: none;
	color: #fff;
}

.ag-courses-item_link:hover .ag-courses-item_bg {
	-webkit-transform: scale(10);
	-ms-transform: scale(10);
	transform: scale(10);
}

.ag-courses-item_title {
	//min-height: 87px;
	margin: 0 0 5px;

	overflow: hidden;

	font-weight: bold;
	font-size: 16px;
	color: #fff;

	z-index: 2;
	position: relative;
}

.ag-courses-item_date-box {
	font-size: 12px;
	color: #fff;

	z-index: 2;
	position: relative;
}

.ag-courses-item_date {
	font-weight: bold;
	color: #f9b234;

	-webkit-transition: color 0.5s ease;
	-o-transition: color 0.5s ease;
	transition: color 0.5s ease;
}

.ag-courses-item_bg {
	height: 128px;
	width: 128px;
	background-color: #6e6e6e;

	z-index: 1;
	position: absolute;
	top: -85px;
	right: -85px;

	border-radius: 50%;

	-webkit-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
	background-color: #3ecd5e;
}

.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
	background-color: #e44002;
}

.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
	background-color: #952aff;
}

.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
	background-color: #cd3e94;
}

.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
	background-color: #4c49ea;
}

@media only screen and (max-width: 979px) {

	// .ag-courses_item {
	//   -ms-flex-preferred-size: calc(50% - 30px);
	//   flex-basis: calc(50% - 30px);
	// }
	.ag-courses-item_title {
		font-size: 24px;
	}
}

@media only screen and (max-width: 767px) {
	.ag-format-container {
		width: 96%;
	}
}

@media only screen and (max-width: 639px) {
	.ag-courses_item {
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.ag-courses-item_title {
		min-height: 72px;
		line-height: 1;

		font-size: 24px;
	}

	.ag-courses-item_link {
		padding: 22px 40px;
	}

	.ag-courses-item_date-box {
		font-size: 16px;
	}
}

.chart-legend .legend-title-text {
	line-height: 20px !important;
}

.ng-autocomplete {
	width: 100% !important;
}

ng-select {
	margin-top: -8px;
}

.dt-length label {
	text-transform: capitalize !important;
	margin: 0 15px;
	font-weight: 600;
}
.selectedC{
	color: #FFC107;
}